<mat-toolbar color="primary" class="mat-elevation-z8 position-fixed">
    <mat-toolbar-row class="justify-between">
      <div class=" logo-wrapper logo-section">
          <a href="javascript:void(0)" routerLink="/leads"><img src="assets/images/logo2.svg" alt="Survey-logo"></a>
          <!-- <span>AvidIntelli</span> -->
      </div>
      <span class="example-spacer"></span>
      <div class="align-center">
        <div class="basic-chip background-white">{{userName}}</div>   
        <button mat-icon-button [matMenuTriggerFor]="menu" *ngIf="showMenu">
          <mat-icon>menu</mat-icon>
        </button>
      </div>
    </mat-toolbar-row>
</mat-toolbar>
<mat-menu #menu="matMenu">
  <button mat-menu-item routerLink="/change-password">
    <mat-icon>lock_reset</mat-icon> 
    <span>Change Password</span>
  </button>
  <button mat-menu-item (click)="logout()">
      <mat-icon>logout</mat-icon> 
      <span>Logout</span>
  </button>
</mat-menu>
