import { Component, OnInit } from '@angular/core';
import { MatTooltip } from '@angular/material/tooltip';
import { NavigationEnd, Router } from '@angular/router';
import { UtilService } from 'src/app/shared/services/util/util.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  showMenu = false;
  urlPath:string = '';
  userName:any = '';
  constructor(
    public util: UtilService,
    private router:Router,
  ) { 
    router.events.subscribe((val) => {
      this.urlPath = router.url;
      if(val instanceof NavigationEnd){
        this.showMenu = this.urlPath != '/choose-plan' && this.urlPath != '/my-qrcode'
      }
    });
  }

  ngOnInit(): void {
    this.userName = this.util.getLoginData()?.name || '';
  }

  logout() {
    this.util.logout();
  }
}
