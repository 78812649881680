export const Constants =  {
    STRING_CONST : {
        TOAST_BTN_NAME: 'Dismiss',
        RETRY_MSG: 'Please retry again'
    },
    ERROR_MSG : {
        INVALID_EMAIL_MOBILE: 'Please enter valid Email or Mobile Number',
        INVALID_EMAIL: 'Enter valid Email',
        INVALID_MOBILE: 'Enter valid Mobile Number',
        INVALID_CONTACT_NUMBER: 'Enter valid Contact Number',
        INVALID_PASSWORD: 'Enter valid Password',
        INVALID_MIN_PASSWORD: 'Password must be 6 to 25 characters long',
        INVALID_PASS_NOT_MATCH: 'Password do not match, please try again.',
        INVALID_PASS_SPACE: 'Space is not allowed in Password, please try again.',
        INVALID_BUSINESS_NAME: 'Enter valid Business Name',
        INVALID_CONTACT_NAME: 'Enter valid Contact Name',
        INVALID_ADDRESS: 'Enter valid Address',
        INVALID_PINCODE: 'Enter valid Pincode',
        INVALID_DESIGNATION: 'Enter Designation',
        INVALID_CITY: 'Please select the City',
        INVALID_ONBOARDING_LEAD: 'Please select the Onboarding Lead',
        INVALID_LEAD_TYPE: 'Please select the Lead Type',
        INVALID_STATUS: 'Please select the Status',
        INVALID_STAGE: 'Please select the Stage',
        INVALID_NAME: 'Enter valid Name',
        INVALID_LINK: 'Please enter valid Link',
        INVALID_BUSINESS_SIZE: 'Please select the Business Size',
        INVALID_INDUSTRY: 'Please select the Industry',
        INVALID_FIRSTNAME: 'Enter valid First Name',
        INVALID_LASTNAME: 'Enter valid Last Name',
        INVALID_BILLING_ADDRESS: 'Enter valid Billing Address',
        INVALID_MOBILE_NO: 'Enter valid Mobile Number',
        RETRY: 'Please retry again'     
    },  
    REGEX: {
        EMAIL_MOBILE: /^([A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4})$|^([0-9]{10,10})$/,
        EMAIL: /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/,
        MOBILE: /^[0-9]{10,10}$/,
        ONLY_NUMBER: /^-?(0|[1-9]\d*)?$/,
        ACCEPT_MULTI_LANGUAGE: /\p{L}+/gmu,
        ALPHA_NUMERIC: /^([a-z A-Z0-9]{2,100})*$/,
        ALPHA_MIN_MAX: /^([a-zA-Z ]{2,100})+$/,
        ALPHA_NUMERIC_SPECIAL_CHAR: /^([a-zA-Z0-9-\s~`!@#$%^&*()_+=\[\]\\<>`]{2,100})+$/,
        LINK: /^(?:(http(s)?|HTTP(S)?):\/\/)?((www|WWW).)?[a-zA-Z0-9]+\.[a-zA-Z]+[\w\-\._~:\/?#[\]@!\$&'\(\)\*\+,;=.]+$/,
        ALPHA_NUMERIC_SPECIAL_CHAR_WITH_SPACE: /^([a-z A-Z0-9~`!@#$%^&*()_+=\\-`.+,\"]{2,100})$/,
        REQUIRED: /[-~]*$/,
        YEAR: /(?:(?:18|19|20|21)[0-9]{2}$)/,
        PINCODE: /^[0-9]{6}$/,
        GST: /[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/,
        PAN: /^([A-Z]){5}([0-9]){4}([A-Z]){1}?$/,
        DIGITS: /^[0-9]$/,
        MAX_FOUR_DIGIT: /^[0-9]{0,4}$/,
        MAX_SIX_DIGIT: /^[0-9]{0,6}$/,
        MAX_TEN_DIGIT: /^[0-9]{0,10}$/,
    },  
    CARD_DATA: [  
        {id:100, name:'Signups', count:'0', cols: 1},  
        {id:101, name:'Subscription', count:'0', cols: 1},  
        {id:102, name:'Revenue', count:'0', cols: 1},  
        {id:103, name:'Growth Template', count:'0', cols: 1},
        {id:104, name:'Responses', count:'0', cols: 1},  
        {id:105, name:'ZO Members', count:'0', cols: 1},  
        {id:106, name:'Leads', count:'0', cols: 1},  
    ],
    STATUS: [
        {id:1, name:'Active'},
        {id:2, name:'Dormant'},
        {id:3, name:'Inactive'},
        {id:4, name:'Incomplete'}
    ],
    ROLES: {
        ADMIN: 1,
        MANAGER: 2,
        ON_BOARD: 3
    },
    STAGE:  [
        {id:1, name:'Successful'},
        {id:2, name:'Unsuccessful'},
        {id:3, name:'In progress'}
    ],
    LEAD_STATUS:  [
        {id:1, name:'Hot'},
        {id:2, name:'Cold'},
        {id:3, name:'Warm'}
    ],
    LEAD_TYPE: [
        {id:"1",name:'Merchant'},
        // {id:"2",name:'Enterprise'},
    ],
    MERCHANT: [
        {name:'0-2',id:"1"},
        {name:'3-5 employees',id:"2"},
        {name:'6-10 employees',id:"3"},
        {name:'more than 10 employees',id:"4"},
    ],
    ENTERPRISE: [
        {name:'Micro business 1-4 employees',id:"1"},
        {name:'Small business-5-99',id:"2"},
        {name:'Medium-100-499',id:"3"},
        {name:'Large-499+',id:"4"},
    ],
    DEMO: [
        {name:'Yes',id:"1"},
        {name:'No',id:"2"},
    ],
    COLUMN_HEADER: {
        ACTIVITY: [
            {name:'Business Name',key:'name'},
            {name:'Status',key:'status'},
            {name:'Business Size',key:'businessSize'},
            {name:'Manager',key:'managerName'},
            {name:'Onboarding Lead',key:'OnboardingLead'}
        ],
        SUBSCRIPTION: [
            {name:'Business Name',key:'name'},
            {name:'Subscription Plan Name',key:'planName'},
            {name:'Purchased Date',key:'purchasedOn'}
        ],
        REVENUE_MONTHLY: [
            {name:'Business Name',key:'name'},
            {name:'Monthly Growth Plan',key:'monthlyTotal'},
            {name:'Annual Growth Plan',key:'annualTotal'},
            {name:'Top Up - Feedback',key:'topupTotal'}
        ],
        REVENUE_ANNUAL: [
            {name:'Business Name',key:'name'},
            {name:'Annual Growth Plan',key:'annualTotal'},
            {name:'Monthly Growth Plan',key:'monthlyTotal'},
            {name:'Top Up - Feedback',key:'topupTotal'}
        ],
        REVENUE_TOPUP: [
            {name:'Business Name',key:'name'},
            {name:'Top Up - Feedback',key:'topupTotal'},
            {name:'Monthly Growth Plan',key:'monthlyTotal'},
            {name:'Annual Growth Plan',key:'annualTotal'}
        ],
        GROWTH_TEMPLATE: [
            {name:'Business Name',key:'name'},
            {name:'GT on QR',key:'templateName'},
            {name:'Total GTs launched on QR',key:'qrLaunch'},
            {name:'Total Mobile launched',key:'mobileLaunch'},
            {name:'Unique Mobile Invitations',key:'uniqueLaunch'}
        ],
        GROWTH_TEMPLATE_QR: [
            {name:'Business Name',key:'name'},
            {name:'GT on QR',key:'templateName'},
            {name:'Total GTs launched on QR',key:'qrLaunch'},
        ],
        GROWTH_TEMPLATE_MOBILE: [
            {name:'Business Name',key:'name'},
            {name:'Total Mobile launched',key:'mobileLaunch'},
            {name:'Unique Mobile Invitations',key:'uniqueLaunch'}
        ],
        GROWTH_TEMPLATE_NO_GT: [
            {name:'Business Name',key:'name'},
            {name:'City',key:'cityName'},
            {name:'Onboarding Lead',key:'customerOnboardingName'},
        ],
        RESPONSES: [
            {name:'Business Name',key:'name'},
            {name:'Total number of responses',key:'totalRespondent'},
            {name:'Total number of unique responses',key:'totalUniqueRespondent'}
        ],
        ZO: [
            {name:'Business Name',key:'name'},
            {name:'Total Number of ZO members',key:'zoMember'},
            {name:'Total PCM (Private Community Members)',key:'pcmMember'},
            {name:'Average Profile Depth',key:'averageProfileDepth'}
        ],
        LEADS: [
            {name:'Business Name',key:'nameOfBusiness'},
            {name:'Lead creation date',key:'created_at'},
            {name:'Contact Name',key:'contactName'},
            {name:'Onboarding Lead',key:'onboardingLead'},
            {name:'Follow Up Date',key:'followUpDate'},
            {name:'Last modified',key:'updated_at'},
            {name:'Status',key:'leadStatus'},
            {name:'Remarks',key:'remark'},
        ]
    }
}