import { Component, Inject, OnInit } from '@angular/core';
import { Constants } from '../../shared/constants';
import { PageService } from '../page.service';
import { UtilService } from '../../shared/services/util/util.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-business-details',
  templateUrl: './business-details.component.html',
  styleUrls: ['./business-details.component.scss']
})
export class BusinessDetailsComponent implements OnInit {
  storeDetails:any = {};
  showLayout:boolean = false;

  constructor(
    public dialogRef: MatDialogRef<BusinessDetailsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    // private dialog:MatDialog,
    private pagesService:PageService,
    private util:UtilService,
  ) { }

  ngOnInit(): void { }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.getStoreDetails();
    });
  }

  // openQrDialog(): void {
  //   const dialogRef = this.dialog.open(QrCodeComponent, {
  //     data: {
  //       businessName: this.storeDetails.business_name,
  //       qrCode: this.storeDetails.qrCode
  //     },
  //   });

  //   dialogRef.afterClosed().subscribe(result => {
  //     if(result){
        
  //     }
  //   });
  // }

  getStoreDetails(){
    this.util.show();
    let data = {
      storeId : this.data?.storeId
    }
    this.pagesService.getStoreDetails(data).subscribe(
      (response: any) => {
        if (response.success === true) { 
          this.storeDetails = response.data;
          this.showLayout = true;
        } else {
          this.util.handleStatusErrorCode(response)
        }
        this.util.hide();     
      },
      (error) => {
        this.util.hide();
        this.openSnackBar(Constants.ERROR_MSG.RETRY)
        console.log(error);
      }
    );
  }

  openSnackBar(message: string) {
    this.util.openSnackBar(message, Constants.STRING_CONST.TOAST_BTN_NAME);
  }

}
