import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthGuard } from '../../guards/auth/auth.guard';
import { UtilService } from '../util/util.service';

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {

  constructor(
    public auth: AuthGuard,
    private util:UtilService
    ) {}

   intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const token = this.util.getToken();
    const skipIntercept = request.headers.has('skip');

    if (skipIntercept) {
        request = request.clone({
            headers: request.headers.delete('skip')
        });
      return next.handle(request);
    }

    if (token) {
      request = request.clone({
        setHeaders: {
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${token}`
        }
      });
    }
    return next.handle(request);
  }
}
