import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PageService {
  apiUrl = environment.apiUrl; 
  constructor(
    private httpClient:HttpClient
  ) { }

  getUserDetails() {
    return this.httpClient.get(`${this.apiUrl}/user/get-user-details`);
  }

  getStoreDetails(data:any) {
    return this.httpClient.post(`${this.apiUrl}/dashboard/get-store-data`, data);
  }

  getCityList() {
    return this.httpClient.post(`${this.apiUrl}/dashboard/get-cities-list`, null);
  }

  getOnboardingLeads() {
    return this.httpClient.post(`${this.apiUrl}/user/get-onboarding-leads`, null);
  }

  getIndustries() {
    return this.httpClient.post(`${this.apiUrl}/dashboard/get-industries-list`, null);
  }
  //-------------------- count API's ------------------
  getActivityCount(data:any) {
    return this.httpClient.post(`${this.apiUrl}/dashboard/get-activity-count`,data);
  }
  getSubscriptionCount(data:any) {
    return this.httpClient.post(`${this.apiUrl}/dashboard/get-subscription-count`,data);
  }
  getRevenueCount(data:any) {
    return this.httpClient.post(`${this.apiUrl}/dashboard/get-revenue-count`,data);
  }
  getTemplateLaunchCount(data:any) {
    return this.httpClient.post(`${this.apiUrl}/dashboard/get-growth-template-count`,data);
  }
  getResponsesCount(data:any) {
    return this.httpClient.post(`${this.apiUrl}/dashboard/get-responses-count`,data);
  }
  getZoCount(data:any) {
    return this.httpClient.post(`${this.apiUrl}/dashboard/get-zo-members-count`,data);
  }
  getLeadsCount(data:any) {
    return this.httpClient.post(`${this.apiUrl}/leads/get-lead-count`,data);
  }
  //---------------------- table API's------------------

  getActivityStats(data:any) {
    return this.httpClient.post(`${this.apiUrl}/dashboard/get-activity-details`,data);
  }

  getSubscriptionStats(data:any) {
    return this.httpClient.post(`${this.apiUrl}/dashboard/get-subscription-list`,data);
  }

  getRevenueStats(data:any) {
    return this.httpClient.post(`${this.apiUrl}/dashboard/get-revenue-list`,data);
  }

  getGrowthTemplateStats(data:any) {
    return this.httpClient.post(`${this.apiUrl}/dashboard/get-growth-template-stats`,data);
  }

  getRespondentStats(data:any) {
    return this.httpClient.post(`${this.apiUrl}/dashboard/get-respondent-stats`,data);
  }

  getZoStats(data:any) {
    return this.httpClient.post(`${this.apiUrl}/dashboard/get-zo-member-stats`,data);
  }

  //-------------- Leads api--------------

  getLeadList(data:any) {
    return this.httpClient.post(`${this.apiUrl}/leads/get-leads`,data);
  }

  getSingleLead(id:any) {
    return this.httpClient.post(`${this.apiUrl}/leads/get-single-lead/${id}`,null);
  }

  createLead(data:any) {
    return this.httpClient.post(`${this.apiUrl}/leads/create-lead`,data);
  }

  editLead(id:any, data:any) {
    return this.httpClient.post(`${this.apiUrl}/leads/update-lead/${id}`,data);
  }

  changePassword(data:any) {
    return this.httpClient.post(`${this.apiUrl}/user/change-password`,data);
  }
}
