import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { BehaviorSubject, distinctUntilChanged } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Constants } from '../../constants';

@Injectable({
  providedIn: 'root'
})
export class UtilService {
  private _loading = new BehaviorSubject<boolean>(false);
  public readonly loading$ = this._loading.asObservable();
  Breakpoints = Breakpoints;
  public currentBreakpoint:string = '';
  readonly breakpoint$ = this.breakpointObserver
    .observe([Breakpoints.Large, Breakpoints.Medium, Breakpoints.Small, Breakpoints.XSmall])
    .pipe(
      distinctUntilChanged()
    );

  constructor(
    private breakpointObserver: BreakpointObserver,
    private router:Router,
    private _snackBar:MatSnackBar,
    private _location: Location
  ) { 
    this.breakpoint$.subscribe(() =>
      this.breakpointChanged()
    );
  }

  breakpointChanged() {
    if (this.breakpointObserver.isMatched(Breakpoints.Large)) {
      this.currentBreakpoint = 'Large';
    } else if (this.breakpointObserver.isMatched(Breakpoints.Medium)) {
      this.currentBreakpoint = 'Medium';
    } else if (this.breakpointObserver.isMatched(Breakpoints.Small)) {
      this.currentBreakpoint = 'Small';
    } else if (this.breakpointObserver.isMatched(Breakpoints.XSmall)) {
      this.currentBreakpoint = 'XSmall';
    }
  }

  show() {
    this._loading.next(true);
  }

  hide() {
    this._loading.next(false);
  }

  backClicked() {
    this._location.back();
  }

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 3000
    });
  }

  ///////// token///////////

  login(token: string) {
    localStorage.setItem('megrow_admin_token', token);
    this.router.navigate(['/leads']);
  }

  getToken() {
    return localStorage.getItem("megrow_admin_token");
  }

  logout() {
    this.clearLocalStorage();
  }

  clearLocalStorage() {
    localStorage.clear();
    this.router.navigate(['/signin']);
  }

  setLoginData(data: string) {
    localStorage.setItem('megrow_admin_data', JSON.stringify(data));
  }

  getLoginData() {
    return JSON.parse(localStorage.getItem("megrow_admin_data") || '{}');
  }

  handleStatusErrorCode(response:any,form:any = {}, showToaster = true){
    if (response.success === false) {
      if (response.status_code === 422) {
        for (const [key, value] of Object.entries(response.data)) {
          let val:any[] = value as []
          form?.controls[key]?.setErrors({
            serverError: val[0]
          });
        }
        if(showToaster){
          this.openSnackBar(response.message,Constants.STRING_CONST.TOAST_BTN_NAME)
        }
      } else if (response.status_code === 401 || response.status_code === 1611 || response.status_code === 1612) {
        this.openSnackBar(response.message, Constants.STRING_CONST.TOAST_BTN_NAME)
      } else if(response.status_code === 1014 || response.status_code === 1086){
        this.openSnackBar(response.message,Constants.STRING_CONST.TOAST_BTN_NAME)
        this.clearLocalStorage()
        this.router.navigateByUrl('/signin');
      } else {
        console.log(response)
        this.openSnackBar(response.message,Constants.STRING_CONST.TOAST_BTN_NAME)
      }
    }
  }

  removeNullVaraible(obj:any){
    Object.keys(obj).forEach((k) => {
      if(obj[k] == 'null' || obj[k] == null|| obj[k] == '' || obj[k].length == 0
       || obj[k] == undefined || obj[k] == 'NaN-NaN-NaN'){
        delete obj[k]
      }
    });
  }

  setNumberValuesUptoSix(form:any,field:string,text :any, pincodeNumber:any){
    return this.checkValidNumber(form, field, text, pincodeNumber,Constants.REGEX.MAX_SIX_DIGIT)
  }

  setNumberValuesUptoTen(form:any, field:string, text :any, mobileNumber:any){
    return this.checkValidNumber(form, field, text, mobileNumber,Constants.REGEX.MAX_TEN_DIGIT)
  }

  checkValidNumber(form:any, field:string, text :any, previousVal:any,regex:any){
    if(text.toString().match(regex)){
      previousVal = text
      return text;
    }else{ 
      form.get(field)?.setValue(previousVal);
      return previousVal;
    }
  }

  setMaxValue(field:string,text :any,max:number,form:FormGroup){
    if(text.length > max) {
      form.get(field)?.setValue(text.substring(0, max));
    }
  }

  setValue(form:any,field:string,text:any){
    text?.toString().trim()
    form.get(field)?.setValue(text);
    form.get(field)?.updateValueAndValidity({emitEvent: false});
  }

  setValidators(form:any,field:string, validator:any[]){
    form.get(field)?.setValidators(validator);
    form.get(field)?.updateValueAndValidity({emitEvent: false});
  }
  
  removeValidators(form:any,field:string){
    form.get(field)?.clearValidators();
    form.get(field)?.updateValueAndValidity({emitEvent: false});
  }

  updateValue(form:any,field:string,text :any){
    form.get(field)?.setValue(text);
    form.get(field)?.markAsUntouched();
  }

  getTextFromClass(className:string,str:string){
    let s = str?.toString();
    let htmlObject = document.createElement('div');
    htmlObject.innerHTML = s;
    let span_Text =  htmlObject.querySelector('.'+className)?.innerHTML;
    return span_Text || '';
  }

  focusAndBlurField(field:string) {
    let fieldControl = document.querySelector('[formcontrolname="' + field + '"]') as HTMLElement;
    fieldControl?.focus();
    fieldControl?.blur();
  }

  onClosedSetEndDate(form:any){
    let data = form.value;
    if((data.start_date && !data.end_date) || data?.end_date?.toString() == 'Invalid Date'){
      form.get('end_date')?.setValue(new Date(data.start_date))
    }
  }

  loadScript():HTMLScriptElement {
    let script = document.createElement("script");
    script.type = "text/javascript";
    script.async = true;
    script.id = 'google-api';
    script.src = 'https://maps.googleapis.com/maps/api/js?key='+environment.googleApiKey+'&libraries=places';
    document.body.appendChild(script);
    return script;
  }

  utcToLocalDate(date:any){
    var gmtDateTime = moment.utc(date)
    return date ? gmtDateTime.local().format('YYYY-MM-DD') : null;
  }

  dateFormat(date:any,format:any){
    return date ? moment(date).format(format) : '';
  }

  setZIndexofCDK(value:string){
    window.document.querySelector<any>('.cdk-overlay-container').style.zIndex = value
  }

  setLeftCss(value:string){
    window.document.querySelector<any>('.cdk-overlay-pane').style.left = value
  }

  setMarginPlacesDialog(value:string){
    window.document.querySelector<any>('.pac-container').style.marginTop = value
  }

  detectBrowserName() {
    const agent = window.navigator.userAgent.toLowerCase()
    switch (true) {
      case agent.indexOf('edg') > -1 || agent.indexOf('edge') > -1:
        return 'edge';
      case agent.indexOf('opr') > -1 && !!(<any>window).opr:
        return 'opera';
      case agent.indexOf('chrome') > -1 && !!(<any>window).chrome:
        return 'chrome';
      case agent.indexOf('trident') > -1:
        return 'ie';
      case agent.indexOf('firefox') > -1:
        return 'firefox';
      case agent.indexOf('safari') > -1:
        return 'safari';
      default:
        return 'other';
    }
  }
}
