import { Component } from '@angular/core';
import { UtilService } from './shared/services/util/util.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'MeGrow-Admin-Dashboard-Frontend';
  loading$ = this.util.loading$;

  constructor(
    public util: UtilService,
  ) {}
}
