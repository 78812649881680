import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppLayoutComponent } from './core/app-layout/app-layout.component';
import { PageGuard } from './shared/guards/page/page.guard';

const routes: Routes = [
  { path: '', redirectTo: '/signin', pathMatch: 'full' },
  { path: '', loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule), },
  { path: '', component:AppLayoutComponent, loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule), canLoad:[PageGuard] },
  // { path: 'error', component: ErrorComponent },
  // { path: 'linkExpired', component: LinkExpiredComponent },
  // { path: '**', component: ErrorComponent }
];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
