<h1 mat-dialog-title>{{storeDetails?.storeName}}</h1>
<mat-dialog-content>
    <div *ngIf="showLayout" class="business-details">
        <!-- <div class="text-center mt-4">
            <h1 class="mb-0 business-hdg mr-5">{{storeDetails?.storeName}}</h1>
            <button mat-icon-button color="primary" class="qr_icon_btn" (click)="openQrDialog()" onclick="this.blur()">
                <mat-icon>qr_code</mat-icon>
            </button>
        </div> -->
        <div fxLayout="column" class="label-container">
            <label>Contact Name</label>
            <h4> {{storeDetails?.contactName}}</h4>
        </div>
        <div fxLayout="column" class="label-container">
            <label>Address</label><h4> {{storeDetails?.address}}</h4>
        </div>
        <div fxLayout="row" class="label-container">
            <div class="col">  
                <label>City</label><h4> {{storeDetails?.city}}</h4>
            </div>
            <div class="col">  
                <label>Pincode</label><h4> {{storeDetails?.pincode}}</h4>
            </div>
        </div>
        <div fxLayout="row" class="label-container">
            <div class="col">  
                <label>Year of establishment</label><h4> {{storeDetails?.year_of_establishment ? storeDetails?.year_of_establishment : '--'}}</h4>
            </div>
            <div class="col">  
                <label>GST</label><h4> {{storeDetails?.gst ? storeDetails?.gst : '--'}}</h4>
            </div>
        </div>
        <div fxLayout="column" class="label-container">
            <label>Email</label><h4> {{storeDetails?.email ? storeDetails?.email : '--'}}</h4>
        </div>
        <div fxLayout="column" class="label-container">
            <label>Mobile</label><h4> {{storeDetails?.mobile}}</h4>
        </div>
        <div fxLayout="column" class="label-container">
            <label>Industry</label><h4> {{storeDetails?.industry ? storeDetails?.industry : '--'}}</h4>
        </div>
        <div fxLayout="column" class="label-container">
            <label>Onboarding Lead</label><h4> {{storeDetails?.onBoardingLead ? storeDetails?.onBoardingLead : '--'}}</h4>
        </div>
        <div fxLayout="column" class="label-container">
            <label>Business Size</label><h4> {{storeDetails?.businessSize ? storeDetails?.businessSize : '--'}}</h4>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close color="warn">Close</button>
</mat-dialog-actions>
